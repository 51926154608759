import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

const Links = () => {
  return (
    <div className="links">
      <a href="https://www.github.com/reginavlee" target="_blank" rel="noreferrer noopener">
        <FontAwesomeIcon icon={faGithub} size="1x" />
      </a>
      <a href="https://www.linkedin.com/in/reginavlee" target="_blank" rel="noreferrer noopener">
        <FontAwesomeIcon icon={faLinkedin} size="1x" />
      </a>
      <a href="https://www.twitter.com/reginavlee" target="_blank" rel="noreferrer noopener">
        <FontAwesomeIcon icon={faTwitter} size="1x" />
      </a>
    </div>
  )
}

export default Links;

import React from "react"
import { Link } from "gatsby"

import Nav from '../components/nav';
import Links from '../components/links';
import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children, mainPaddingFactor = 0 }) => {
  // const rootPath = `${__PATH_PREFIX__}/`;

  return (
    <div
      style={{
        height: `100%`,
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(30),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)} 0 ${rhythm(3 / 4)}`,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3
            style={{
              fontFamily: `Montserrat, sans-serif`,
              margin: 0,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
          <Nav />
        </div>
      </header>
      <main style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', padding: `0 ${rhythm(mainPaddingFactor)}` }}>
        {children}
      </main>
      <footer
        style={{ display: `flex`, flexDirection: 'column', marginBottom: `${rhythm(1.5)}` }}
      >
        <Links />
        <div style={{ textAlign: 'center' }}>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </div>
      </footer>
    </div>
  )
}

export default Layout

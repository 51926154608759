import React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

const Nav = () => {
  return (
    <div className="nav">
      <Link to="/blog" style={{ marginRight: '1em' }}>
        Blog <span role="img" aria-label="blog">✍️</span>
      </Link>
      <a href={`resume.pdf`} download>
        Resume <span role="img" aria-label="resume">📋</span>
      </a>
      <ThemeToggler>
        {({ theme, toggleTheme }) => {
          if (theme == null) {
            return null
          }
          return (
            <div className="toggleWrapper">
              <input
                type="checkbox"
                className="daynight"
                id="daynight"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
              />
              <label htmlFor="daynight" className="toggle">
                <span className="toggle__handler">
                  <span className="crater crater--1"></span>
                  <span className="crater crater--2"></span>
                  <span className="crater crater--3"></span>
                </span>
                <span className="star star--1"></span>
                <span className="star star--2"></span>
                <span className="star star--3"></span>
                <span className="star star--4"></span>
                <span className="star star--5"></span>
                <span className="star star--6"></span>
              </label>
            </div>
          )
        }}
      </ThemeToggler>
    </div>
  )
}

export default Nav;
